<template>
  <div>
    <h3>イベントの繰り返し設定</h3>

    <!-- 日付入力 -->
    <div>
      <label for="start-date">開始日</label>
      <input
        type="date"
        id="start-date"
        v-model="startDate"
        @change="onDateChange"
      />
    </div>

    <!-- 繰り返し設定プルダウン -->
    <div>
      <label for="recurrence">繰り返し設定</label>
      <select id="recurrence" v-model="selectedRecurrence">
        <option
          v-for="option in recurrenceOptions"
          :key="option"
          :value="option"
        >
          {{ option }}
        </option>
      </select>
    </div>

    <!-- 保存ボタン -->
    <button @click="saveRecurrence">保存</button>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  setup() {
    // 日付と繰り返し設定
    const startDate = ref("");
    const selectedRecurrence = ref("");
    const recurrenceOptions = ref([]);

    // 繰り返しオプションを生成する関数
    const generateRecurrenceOptions = (selectedDate) => {
      const dayOfWeek = getDayOfWeek(selectedDate);
      const dayOfMonth = new Date(selectedDate).getDate();
      const month = new Date(selectedDate).getMonth() + 1;

      return [
        "毎日",
        `毎週 ${dayOfWeek}`,
        `隔週 ${dayOfWeek}`,
        `毎月 第1${dayOfWeek}`,
        `毎月 ${dayOfMonth}日`,
        `毎年 ${month}月${dayOfMonth}日`,
      ];
    };

    // 日付変更時に繰り返しオプションを更新
    const onDateChange = () => {
      recurrenceOptions.value = generateRecurrenceOptions(startDate.value);
    };

    // 曜日を取得する関数
    const getDayOfWeek = (date) => {
      const days = [
        "日曜日",
        "月曜日",
        "火曜日",
        "水曜日",
        "木曜日",
        "金曜日",
        "土曜日",
      ];
      return days[new Date(date).getDay()];
    };

    // 繰り返し設定の保存
    const saveRecurrence = async () => {
      try {
        const response = await fetch("/api/recurrences", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            event_id: 1, // ダミーのイベントID（本来は選択したイベントのIDを使用）
            pattern: selectedRecurrence.value,
          }),
        });

        const result = await response.json();
        alert("繰り返し設定が保存されました");
      } catch (error) {
        console.error("Error saving recurrence:", error);
      }
    };

    return {
      startDate,
      selectedRecurrence,
      recurrenceOptions,
      onDateChange,
      saveRecurrence,
    };
  },
};
</script>
